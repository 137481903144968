import { useParams, Link, Navigate } from "react-router-dom";
import regionsData from "../datas/regions.json";
import "../Custom.css";

const Region = () => {
  const { slug } = useParams();

  const region = regionsData.find((region) => region.slug === slug);
  region.treks.map((trek, index) => {
    console.log("Image path:", `/${trek.name}.jpg`)
  });

  if (!region) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <div className="container mt-4 containerCardTrek">
      {/* Breadcrumb */}
      <nav aria-label="breadcrumb" className="breadcrumb_container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {region.name}
          </li>
        </ol>
      </nav>

      <h1 className="mb-4 regionBigTitle">{region.name}</h1>

      <section className="cards-grid">
        {region.treks.map((trek, index) => (
          <article className={`card card--${trek.slug}`} key={trek.slug}>

            <div className="card__info-hover">
              <svg className="card__like" viewBox="0 0 24 24">
                <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,..." />
              </svg>
              <div className="card__clock-info">
                <svg className="card__clock" viewBox="0 0 24 24">
                  <path d="M12,20A7,7..." />
                </svg>
                <span className="card__time">{trek.duration} jours</span>
              </div>
            </div>
            <div
              className="card__img"
              style={{ backgroundImage: `url(/${encodeURIComponent(trek.name)}.jpg)` }}
            ></div>
            <Link
              to={`/region/${region.slug}/${trek.slug}`}
              className="card_link"
            >
              <div
                className="card__img--hover"
                style={{ backgroundImage: `url(/${trek.name}.jpg)` }}
              ></div>
            </Link>
            <div className="card__info d-flex flex-column justify-content-between h-100">
  <div>
    <span className="card__category">Trek</span>
    <h3 className="card__title">{trek.name}</h3>
    <p className="card__by">{trek.short_description}</p>
  </div>

  <Link
    to={`/region/${region.slug}/${trek.slug}`}
    className="card__button mt-3"
  >
    View More
  </Link>
</div>
          </article>
        ))}
      </section>
    </div>
  );
};

export default Region;

import React from 'react';
import '../MountainScroller.css';

const MountainScroller = () => {
  const images = ['/mt4.png','/mt1.png','/mt5.png', '/mt2.png'];
  const repeatedImages = Array(50).fill(images).flat(); // mt-1, mt-2, mt-1, mt-2...

  return (
    <div className="mountains-container">
      <div className="mountains-strip-wrapper">
        <div className="mountains-strip">
          {repeatedImages.map((src, i) => (
            <img
              key={`a-${i}`}
              src={src}
              alt="montagne"
              className="mountain"
              style={{ '--i': i }}
            />
          ))}
        </div>
        <div className="mountains-strip">
          {repeatedImages.map((src, i) => (
            <img
              key={`b-${i}`}
              src={src}
              alt="montagne"
              className="mountain"
              style={{ '--i': i }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MountainScroller;

import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-PL5MP9HXJX"; // remplace par ton ID GA4

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const trackPage = (pagePath) => {
  ReactGA.send({ hitType: "pageview", page: pagePath });
};

export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

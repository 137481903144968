import React from 'react';
import '../RepeatingPattern.css';

const RepeatingPattern = ({ animated = false }) => {
  return (
    <div className={`repeating-pattern ${animated ? 'animated' : 'static'}`} />
  );
};

export default RepeatingPattern;


import React from "react";
import taan_logo from '../images/taan.png';
import natta_logo from '../images/natta.png';
import pinterest_logo from '../images/pinterest.png';
import instagramm_logo from '../images/insta.png';
import SupportLogo from './SupportLogo';
import FooterEdge from "./FooterEdge";
const Footer = () => {
    return (
        <footer className="containerfooterTag">
            <div className="main_container_footer">
                <div className="division_parts_footer">
                    {/* <div className="container_loti_file">

                    </div> */}
                    <div className="part_left_footer">


                        <div className="find_us_container">

                            <SupportLogo
                                image={taan_logo}
                                text="We also support"
                                link="https://www.taan.org.np/"
                                curve="bottom"
                            />


                            <SupportLogo
                                image={natta_logo}
                                text="We also support"
                                link="https://natta.org.np/"
                                curve="top"
                            />
                            <SupportLogo
                                image={pinterest_logo}
                                text="Find us on..."
                                link="https://www.pinterest.com/madhavkhanal231/"
                                curve="bottom"
                            />

                            <SupportLogo
                                image={instagramm_logo}
                                text="Find us on..."
                                link="https://www.instagram.com/nepal_first/"
                                curve="top"
                            />


                        </div>

                        <div className="we_support_container">


                        </div>

                    </div>
                    <div className="part_right_footer">
                        <p>We are on Facebook</p>

                        <script async defer crossOrigin="anonymous"
                            src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v18.0"
                            nonce="yourNonceValue">
                        </script>
                        <div className="fb-page"
                            data-href="https://www.facebook.com/treak.2016"
                            data-tabs=""
                            data-width=""
                            data-height=""
                            data-small-header="false"
                            data-adapt-container-width="true"
                            data-hide-cover="false"
                            data-show-facepile="true">
                        </div>
                    </div>
                </div>

                <div className="selfpromote">
                    <p>AAA Tours and Travels © 2025&nbsp;</p>
                    <p> | Developed by Rony Lhomer</p>
                </div>
            </div>

            <div className="footer-triangle-border" />
            {/* <div className="footer-triangle-border2" /> */}
        </footer>
    );
};

export default Footer;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import NotFound from "./components/NotFound";
import Home from "./components/Home";
import Region from "./components/Regions";
import TrekDetails from "./components/TrekDetails";
import Gallery from "./components/Gallery";
import Advices from "./components/Advices";
import RepeatingPattern from "./components/RepeatingPattern";
import { useEffect } from "react";
import { initGA, trackPage } from "./analytics";
import { useLocation } from "react-router-dom";
import AppRoutes from "./AppRoutes";


function AppContent() {
  const location = useLocation();

  useEffect(() => {
    initGA(); // initialise GA au chargement
  }, []);

  useEffect(() => {
    trackPage(location.pathname + location.search);
  }, [location]);

  return (
    <div className="App">
      <Navbar />
      <main>
        <AppRoutes/>
        {/* <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/region/:slug" element={<Region />} />
          <Route path="/region/:slug/:trekSlug" element={<TrekDetails />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/advices" element={<Advices />} />
          <Route path="*" element={<NotFound />} />
        </Routes> */}
      </main>

      <RepeatingPattern animated />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;

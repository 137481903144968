import React from 'react';
import '../GlitchImage.css';

const GlitchImage = ({ src, alt = "", className = "", style = {} }) => {
  return (
    <div className={`glitch-wrapper ${className}`} style={style}>
      <img className="glitch-base" src={src} alt={alt} />
      {[...Array(4)].map((_, i) => (
        <img
          key={i}
          className={`glitch__item glitch-layer-${i + 2}`}
          src={src}
          alt=""
        />
      ))}
    </div>
  );
};

export default GlitchImage;

import React from "react";

const Upper = ({ logoSize }) => {
  return (
    <div className="upperbox">
      <a className="a_container_logo" href="#">
        <div
          className="logoContainer"
        >
          <img src="/aaatreks_logo.png" alt="AAA Treks & Expeditions" />
        </div>
        <div className="societyName">
          <span>AAA Treks</span>
          <span>& Expeditions</span>
        </div>
      </a>
    </div>
  );
};

export default Upper;

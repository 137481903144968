import React, { useRef, useState } from "react";
import Lottie from "lottie-react";
import compasslotie from "../compass.json";

const Compass = () => {
  const lottieRef = useRef();
  const [heading, setHeading] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const getDirection = (deg) => {
    const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
    const index = Math.round(deg / 45) % 8;
    return directions[index];
  };

  const handleFrameUpdate = (e) => {
    const currentFrame = e.currentTime;
    const totalFrames = e.totalTime;
    const degrees = Math.round((currentFrame / totalFrames) * 360) % 360;
    setHeading(degrees);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
    lottieRef.current.pause();
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    lottieRef.current.play();
  };

  return (
    <div className="main-container-compass" style={{ position: "relative" }}>
      <div
        className="container_loti_file"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Lottie
          lottieRef={lottieRef}
          animationData={compasslotie}
          loop={true}
          autoplay={true}
          className="lottieFile"
          onEnterFrame={handleFrameUpdate}
        />
        <div
          className="compass-heading"
          style={{
            position: "absolute",
            textAlign:'center',
            width:'80px',

            bottom: -10,
            left: "50%",
            transform: "translateX(-50%)",
 
            color: "#1D4168",
            fontFamily: "monospace",
            fontSize: "14px",
            padding: "4px 8px",
            borderRadius: "5px",
            pointerEvents: "none",
          }}
        >
          {heading}° {getDirection(heading)}
        </div>
      </div>
    </div>
  );
};

export default Compass;

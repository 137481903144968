import React, { useRef, useState } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

const API_KEY = "AIzaSyAJeYJ8VhQJLmApTji-HZa1sdarGG9O5EI";


const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 28.207024706573776,
  lng: 83.96265987979388,
};

const placeId = 'ChIJraf8exuVlTkR6GXgNeivQdg'; // <– récupérable via Google Maps

function Map() {
  const [placeInfo, setPlaceInfo] = useState(null);
  const mapRef = useRef(null);

  const onLoad = (map) => {
    mapRef.current = map;

    const service = new window.google.maps.places.PlacesService(map);

    service.getDetails(
      {
        placeId,
        fields: ['name', 'formatted_address', 'rating', 'user_ratings_total', 'url'],
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPlaceInfo(place);
        } else {
          console.error('Place details request failed:', status);
        }
      }
    );
  };

  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalf = rating - fullStars >= 0.5;
  
    for (let i = 0; i < fullStars; i++) {
      stars.push(<span key={`full-${i}`}>⭐</span>);
    }
  
    if (hasHalf) {
      stars.push(<span key="half">⭒</span>); // ou demi-étoile personnalisée
    }
  
    const emptyStars = 5 - stars.length;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<span key={`empty-${i}`}>☆</span>);
    }
  
    return stars;
  };
  

  return (
    <div className="mapcontainer">
      <LoadScript googleMapsApiKey={API_KEY} libraries={['places']}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={17}
          onLoad={onLoad}
        >
          <MarkerF position={center} />
        </GoogleMap>
      </LoadScript>

      {placeInfo && (
        <div className="map-info-box">
          <h3>{placeInfo.name}</h3>
          <p>{placeInfo.formatted_address}</p>
          <p className='containerMIB' style={{ fontSize: "20px", display: "flex", alignItems: "center", gap: "1px" }}>
  {renderStars(placeInfo.rating)}
  
  
  <span className='containerMIB miB2' style={{ fontSize: "16px", color: "#ccc" }}
  >
    {placeInfo.rating}  – {"  "}
    <a
      href={placeInfo.url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ fontWeight: "bold", textDecoration: "underline", textTransform: "lowercase", color:"#0C1B2E" }}
    >
      {placeInfo.user_ratings_total} reviews
    </a>
  </span>
</p>


          {/* <p>
  ⭐ {placeInfo.rating} / 5 –{" "}
  <a
    href={placeInfo.url}
    target="_blank"
    rel="noopener noreferrer"
    style={{ fontWeight: "bold", textDecoration: "underline" }}
  >
    {placeInfo.user_ratings_total} reviews
  </a>
</p> */}
          <div className="map-links">
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${center.lat},${center.lng}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Itinéraire
            </a>
            <span> | </span>
            <a
              href={placeInfo.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Agrandir le plan
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(Map);

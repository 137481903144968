const TripCircle = ({ filled }) => (
    <svg width="22" height="22" viewBox="0 0 22 22">
      {/* Cercle plein (intérieur) */}
      {filled && (
        <circle
          cx="11"
          cy="11"
          r="6"
          fill="#58b370" // vert foncé
        />
      )}
      {/* Cercle contour (extérieur) */}
      <circle
        cx="11"
        cy="11"
        r="9"
        fill="none"
        stroke="#58b370"
        strokeWidth="2"
      />
    </svg>
  );
export default TripCircle;  
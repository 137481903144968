import React from "react"
import '../Advices.css'

const Advices = () => {
    return (
        <section className="main-container-advices">

        </section>
    )

}


export default Advices;
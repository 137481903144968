import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';


const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {isVisible && (
        <button
          className="back-to-top-button"
          onClick={handleBackToTop}
        >
          <FaArrowUp />
          <span className="sr-only">Retour en haut</span>
        </button>
      )}
    </>
  );
};

export default BackToTop;

import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Reviews.css";
import tripAdvisorLogo from '../images/tripadvisor_logo.webp';
import TripCircle from '../components/TripCircle';
const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(3);

  // Détection manuelle de breakpoints
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 800) {
        setCardsPerPage(1); // smartphone
      } else if (width < 1200) {
        setCardsPerPage(2); // tablette
      } else {
        setCardsPerPage(3); // desktop
      }
    };

    handleResize(); // Initial
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    axios
      .get("https://stickystraydog.com/apiaaatrek/reviews")
      .then((response) => {
        setReviews(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des reviews :", error);
        setLoading(false);
      });
  }, []);

  const handlePrev = () => {
    setIndex((prev) => Math.max(prev - cardsPerPage, 0));
  };

  const handleNext = () => {
    setIndex((prev) =>
      Math.min(prev + cardsPerPage, reviews.length - cardsPerPage)
    );
  };

  const visibleReviews = reviews.slice(index, index + cardsPerPage);

  return (
    <>
    <div className="py-5 reviewMainContainer">
      <h2 className="text-center mb-4 reviewTitle">What our mountaineers say about us</h2>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <p>Loading reviews...</p>
        </div>
      ) : reviews.length > 0 ? (
        <div className="d-flex justify-content-center align-items-center review-row">
          {/* Flèche gauche */}
          <button
            onClick={handlePrev}
            disabled={index === 0}
            className="arrow-btn"
          >
            ←
          </button>

          {/* Cartes visibles */}
          <div className="cards-container d-flex">
            {visibleReviews.map((review, idx) => (
              <div key={idx} className="card review-card shadow p-3 mx-2">
                <div className="d-flex align-items-center containerImgName">
                  <img
                    src={review.userpic || "https://via.placeholder.com/50"}
                    alt={review.reviewer_name}
                    className="rounded-circle mb-2"
                    width="50"
                    height="50"
                  />
                  <div>
                    <h5 className="mb-0">{review.reviewer_name}</h5>
                    <small className="text-muted">
                      {new Date(review.created_time).toLocaleDateString()}
                    </small>
                  </div>
                </div>
                <div className="mt-3 containerRondsEtComment">
                  <p className="reviewtext">{review.review_text}</p>
                  <div className="d-flex align-items-center containerRonds">
  {[...Array(5)].map((_, i) => (
    <TripCircle key={i} filled={i < review.rating} />
  ))}
</div>

                </div>

                <div className="containerTripAdvisor">
                  
                  <div className="containerFlexTA">
                    <p>
                      <a href="https://www.tripadvisor.com/Attraction_Review-g293891-d13356000-Reviews-AAA_Treks_Expedition-Pokhara_Gandaki_Zone_Western_Region.html" target="blank" className="vmtripadvisor">
                      View more ...
                      </a>
                    </p>
                    <img src={tripAdvisorLogo} alt="logo de TripAdvisor" className="tripAdvisorLogo"></img>
                  </div>

                </div>
              </div>
            ))}
          </div>

          {/* Flèche droite */}
          <button
            onClick={handleNext}
            disabled={index + cardsPerPage >= reviews.length}
            className="arrow-btn"
          >
            →
          </button>
        </div>
      ) : (
        <p className="text-center">No reviews available at the moment.</p>
      )}
    {/* <PrayerFlags/> */}
    </div>
    </>
  );
};

export default Reviews;

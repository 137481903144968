import React, { useEffect, useRef } from "react";

const Carousel = () => {
  const imageRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const offset = scrollY * 0.2; 

      imageRefs.current.forEach((img) => {
        if (img) {
          img.style.transform = `translateY(${offset}px)`;
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="prestaCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">

        {/* Slide 0 */}
        <div className="carousel-item active">
          <img
            src="/photo_test3.webp"
            className="d-block w-100 parallax-img"
            alt="Slide 1"
            ref={(el) => (imageRefs.current[0] = el)}
          />
          <div className="carousel-caption">
            <h2>Conquer the Heights</h2>
            <p>Challenge yourself with the most spectacular treks.</p>
          </div>
        </div>

        {/* Slide 1 */}
        <div className="carousel-item">
          <img
            src="/photo_test1.webp"
            className="d-block w-100 parallax-img"
            alt="Slide 2"
            ref={(el) => (imageRefs.current[1] = el)}
          />
          <div className="carousel-caption">
            <h2>Explore the Mountains</h2>
            <p>Discover breathtaking landscapes and thrilling adventures.</p>
          </div>
        </div>

        {/* Slide 2 */}
        <div className="carousel-item">
          <img
            src="/photo_test2.webp"
            className="d-block w-100 parallax-img"
            alt="Slide 3"
            ref={(el) => (imageRefs.current[2] = el)}
          />
          <div className="carousel-caption">
            <h2>Adventure Awaits</h2>
            <p>Experience the beauty of untouched nature and high peaks.</p>
          </div>
        </div>

      </div>

      {/* Contrôles Bootstrap */}
      <button className="carousel-control-prev" type="button" data-bs-target="#prestaCarousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#prestaCarousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
      </button>
    </div>
  );
};

export default Carousel;

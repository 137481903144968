

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const UpperBanner = () => {

  return(
    <section className="upperbannercontainer">

      <div className="secondcontainerbanner">


        <div className="firstRow-secondContainer">
            <div className="containerIcon">
              <FontAwesomeIcon icon={faFacebookF} size="1x" className="styleIcon" />
            </div>

            <div className="containerMailInfo">
              <p className="containerIcon">
                <FontAwesomeIcon icon={faEnvelope} size="1x" className="styleIcon"/>
              </p>
              <p className="containerTextMail">
                  Email: info@aaatreks.com
              </p>
            </div>
        </div>



        <div className="secondRow-secondContainer">
            <div className="containerPhoneInfo"> 
              <p className="containerIcon">
                <FontAwesomeIcon icon={faPhone} size="1x" className="styleIcon"/>
              </p>
              <p className="containerTextMail">
                Call Now: +977- 9846481327/061-464180|24 hrs Service
              </p>
            </div>
        </div>




      </div>
              
      

        
    </section>

  )



}


export default UpperBanner;
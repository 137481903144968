import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();

  // États pour gérer les erreurs et le succès
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  // Fonction pour valider les champs
  const validateForm = (formData) => {
    let newErrors = {};

    // Validation de l'email (format)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = "Veuillez entrer une adresse e-mail valide.";
    }

    // Validation du Full Name (min 3, max 50 caractères)
    if (!formData.full_name || formData.full_name.length < 3 || formData.full_name.length > 50) {
      newErrors.full_name = "Le nom doit contenir entre 3 et 50 caractères.";
    }

    // Validation du Message (min 10, max 420 caractères)
    if (!formData.message || formData.message.length < 10 || formData.message.length > 420) {
      newErrors.message = "Le message doit contenir entre 10 et 420 caractères.";
    }

    return newErrors;
  };

  // Fonction d'envoi de l'e-mail
  const sendEmail = (e) => {
    e.preventDefault();
    setSuccessMessage(""); // Réinitialiser le message de succès

    // Récupérer les valeurs du formulaire
    const formData = {
      full_name: form.current.full_name.value.trim(),
      email: form.current.email.value.trim(),
      phone_number: form.current.phone_number.value.trim(),
      message: form.current.message.value.trim(),
    };

    // Vérifier la validation des champs
    const newErrors = validateForm(formData);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Si tout est OK, envoyer l'e-mail via EmailJS
    emailjs
      .sendForm(
        // "service_l6y740d",
        "service_up9qhfe",
        "template_jf2x8rk",
        // "template_3654z0w",
        form.current,
        // "y0TU0Y0KMxBKt-BhB"
        "QwaW4cD1EZG0un1pM"
      )
      .then(
        (result) => {
          console.log("E-mail envoyé avec succès !", result.text);
          setSuccessMessage("Votre message a bien été envoyé !");
          setErrors({}); // Réinitialiser les erreurs
          form.current.reset(); // Réinitialiser le formulaire
        },
        (error) => {
          console.log("Erreur lors de l'envoi", error.text);
          setErrors({ form: "Erreur lors de l'envoi du message. Veuillez réessayer." });
        }
      );
  };

  return (
    <div className="form-main" id="contact">
      <div className="main-wrapper">
        <h2 className="form-head">Please Leave us a message. We will reply you very soon!</h2>

        {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
        {errors.form && <p style={{ color: "red" }}>{errors.form}</p>}

        <form ref={form} className="form-wrapper" onSubmit={sendEmail}>
          {/* Full Name */}
          <div className="form-card">
            <input className="form-input" type="text" name="full_name" required />
            <label className="form-label" htmlFor="full_name">Full Name</label>
            {errors.full_name && <p style={{ color: "red" }}>{errors.full_name}</p>}
          </div>

          {/* Email */}
          <div className="form-card">
            <input className="form-input" name="email" required />
            <label className="form-label" htmlFor="email">Email</label>
            {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
          </div>

          {/* Phone Number (optionnel) */}
          <div class="form-card">
        <input
            class="form-input"
            name="phone_number"
            required="required"
        />
        <label class="form-label" for="phone_number">Phone number</label>
        </div>


          {/* Message */}
          <div className="form-card">
            <textarea
              className="form-textarea"
              maxLength="420"
              rows="3"
              name="message"
              required
            ></textarea>
            <label className="form-textarea-label" htmlFor="message">Message</label>
            {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
          </div>

          {/* Bouton Submit */}
          <div className="btn-wrap">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

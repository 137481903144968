import React from 'react';
import '../PrayersFlag.css';

const PrayerFlags = () => {
  const flags = [
    "/flag-green.webp",
    "/flag-red.webp",
    "/flag-yellow.webp",
    "/flag-blue.webp",
    "/flag-white.webp",
    "/flag-orange.webp",
  ];

  const repeatedFlags = Array(10).fill(flags).flat(); // assez pour couvrir la largeur

  return (
    <div className="prayer-flags-container">
      <div className="prayer-flags-strip-wrapper">
        <div className="prayer-flags-strip">
          {repeatedFlags.map((src, i) => (
            <img
              key={`a-${i}`}
              src={src}
              alt="flag"
              className="flag"
              style={{ '--i': i }}
            />
          ))}
        </div>
        {/* Deuxième bande pour l'effet infini */}
        <div className="prayer-flags-strip">
          {repeatedFlags.map((src, i) => (
            <img
              key={`b-${i}`}
              src={src}
              alt="flag"
              className="flag"
              style={{ '--i': i }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrayerFlags;

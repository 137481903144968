import React from "react";


const ScrollToMapButton = () => {
  const handleScroll = () => {
    const target = document.getElementById("anchornepalmap");
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="scroll-btn-wrapper">
      <button className="scroll-to-map-btn button-713" onClick={handleScroll}>
        View more
      </button>
    </div>
  );
};

export default ScrollToMapButton;

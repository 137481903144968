import React, { useState, useEffect } from "react";
import Upper from "./Upper";
import UpperBanner from "./UpperBanner";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeItem, setActiveItem] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        closeMenuWithAnimation();
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuOpen]);

  const closeMenuWithAnimation = () => {
    setIsAnimatingOut(true);
    setTimeout(() => {
      setIsMenuOpen(false);
      setIsAnimatingOut(false);
    }, 400); // durée = animation CSS
  };

  const handleNavItemClick = (callback) => {
    closeMenuWithAnimation();
    if (typeof callback === "function") callback();
  };

  return (
    <div className="general_container_navBar">
      <div className="firstRowMenu">
        <UpperBanner />
      </div>
      <div className="secondRowMenu">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <Upper />

            <a className="navbar-brand" href="#"></a>

            <button
              className={`navbar-toggler ${isMenuOpen ? "open" : ""}`}
              type="button"
              onClick={() => {
                if (isMenuOpen) {
                  closeMenuWithAnimation();
                } else {
                  setIsMenuOpen(true);
                }
              }}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`collapse navbar-collapse justify-content-end ${
                isMenuOpen
                  ? isAnimatingOut
                    ? "animating-out"
                    : "show animated-menu"
                  : ""
              }`}
              id="navbarNav"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a
                    className={`nav-link ${activeItem === "Home" ? "active" : ""}`}
                    href="/"
                    onClick={() =>
                      handleNavItemClick(() => setActiveItem("Home"))
                    }
                  >
                    Home
                  </a>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className={`nav-link ${activeItem === "About" ? "active" : ""} dropdown-toggle`}
                    href="#about"
                    role="button"
                    data-bs-toggle="dropdown"
                    onClick={() => setActiveItem("About")}
                  >
                    About
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavItemClick(() => {
                            setActiveItem("About");

                            if (location.pathname !== "/") {
                              navigate("/", { replace: false });
                              setTimeout(() => {
                                const aboutSection = document.getElementById("about_nepal");
                                if (aboutSection) aboutSection.scrollIntoView({ behavior: "smooth" });
                              }, 300);
                            } else {
                              const aboutSection = document.getElementById("about_nepal");
                              if (aboutSection) aboutSection.scrollIntoView({ behavior: "smooth" });
                            }
                          });
                        }}
                      >
                        About
                      </a>
                    </li>
                    <li><a className="dropdown-item" href="#">Our team</a></li>
                    <li><a className="dropdown-item" href="#">Videos</a></li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavItemClick(() => {
                            setActiveItem("Gallery");
                            navigate("/gallery");
                          });
                        }}
                      >
                        Gallery
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${activeItem === "Nepal" ? "active" : ""}`}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavItemClick(() => {
                        setActiveItem("Nepal");
                        if (location.pathname !== "/") {
                          navigate("/", { replace: false });
                          setTimeout(() => {
                            const section = document.getElementById("nepal-map");
                            if (section) section.scrollIntoView({ behavior: "smooth" });
                          }, 300);
                        } else {
                          const section = document.getElementById("nepal-map");
                          if (section) section.scrollIntoView({ behavior: "smooth" });
                        }
                      });
                    }}
                  >
                    Nepal
                  </a>
                </li>

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
                    Trekking
                  </a>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/region/annapurna-region" onClick={() => handleNavItemClick()}>Annapurna Region</Link></li>
                    <li><Link className="dropdown-item" to="/region/everest-region" onClick={() => handleNavItemClick()}>Everest Region</Link></li>
                    <li><Link className="dropdown-item" to="/region/dhaulagiri-region" onClick={() => handleNavItemClick()}>Dhaulagiri Region</Link></li>
                    <li><Link className="dropdown-item" to="/region/dolpo-region" onClick={() => handleNavItemClick()}>Dolpo Region</Link></li>
                    <li><Link className="dropdown-item" to="/region/kanchanjanga-region" onClick={() => handleNavItemClick()}>Kanchanjanga Region</Link></li>
                    <li><Link className="dropdown-item" to="/region/langtang-region" onClick={() => handleNavItemClick()}>Langtang Region</Link></li>
                    <li><Link className="dropdown-item" to="/region/manasulu-region" onClick={() => handleNavItemClick()}>Manasulu Region</Link></li>
                    <li><Link className="dropdown-item" to="/region/mustang-region" onClick={() => handleNavItemClick()}>Mustang</Link></li>
                  </ul>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${activeItem === "Helicopter" ? "active" : ""}`}
                    href="#"
                    onClick={() =>
                      handleNavItemClick(() => setActiveItem("Helicopter"))
                    }
                  >
                    Helicopter
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${activeItem === "Tour" ? "active" : ""}`}
                    href="#"
                    onClick={() =>
                      handleNavItemClick(() => setActiveItem("Tour"))
                    }
                  >
                    Tour
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${activeItem === "Travels" ? "active" : ""}`}
                    href="#"
                    onClick={() =>
                      handleNavItemClick(() => setActiveItem("Travels"))
                    }
                  >
                    Travels
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${activeItem === "Advices" ? "active" : ""}`}
                    href="#"
                    onClick={() =>
                      handleNavItemClick(() => {
                        setActiveItem("Advices");
                        navigate("/advices");
                      })
                    }
                  >
                    Advices
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${activeItem === "Contact" ? "active" : ""}`}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavItemClick(() => {
                        setActiveItem("Contact");
                        if (location.pathname !== "/") {
                          navigate("/", { replace: false });
                          setTimeout(() => {
                            const contactSection = document.getElementById("contact");
                            if (contactSection) contactSection.scrollIntoView({ behavior: "smooth" });
                          }, 300);
                        } else {
                          const contactSection = document.getElementById("contact");
                          if (contactSection) contactSection.scrollIntoView({ behavior: "smooth" });
                        }
                      });
                    }}
                  >
                    Contact
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;

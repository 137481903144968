import Carousel from "./Carousel";
import NepalMap from "./NepalMap";
import Reviews from "./Reviews";
import ContactForm from "./ContactForm";
import Map from "./Map";
import BackToTop from "./BackToTop";
import ScrollToMapButton from "./ScrollToMapButton";
const Home = () => {
    return (
      <>
      <Carousel />

<ScrollToMapButton /> {/* 👈 Bouton centré entre Carousel & Map */}

<NepalMap />
        <div className="maxRev">
        <Reviews />

        </div>
  
        <div className="contactContainer">
        <div className="footer-triangle-border3" />
          <div className="cc1">
            <ContactForm />
          </div>
          <div className="cc2">
            <Map />
          </div>
        </div>

        <BackToTop/>
      </>
    );
  };
  
  export default Home;
  
import React, { useState } from "react";
import "../Gallery.css";

const images = Array.from({ length: 30 }, (_, i) => `/images/${i + 1}.webp`);
const imagesPerPage = 5;
const totalPages = Math.ceil(images.length / imagesPerPage);

const Gallery = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [invert, setInvert] = useState(false);
  const [sepia, setSepia] = useState(false);

const [showEffect, setShowEffect] = useState(true); // ← nouveau

  const paginatedImages = images.slice(
    currentPage * imagesPerPage,
    (currentPage + 1) * imagesPerPage
  );

  const resetEffects = () => {
    setInvert(false);
    setSepia(false);
    setShowEffect(false);
  };

  return (
<div className="gallery-container">
  {/* PHOTO AVEC EFFET */}
  <div className={`content ${invert ? "invert" : ""} ${sepia ? "sepia" : ""}`}>
    <div
      className="film"
      style={{ backgroundImage: `url(${selectedImage})` }}
    >
      {showEffect && (
        <div className="effect">
          <div className="grain" />
        </div>
      )}
    </div>
  </div>

  {/* BOUTONS DE FILTRES */}
  <div className="effect-buttons">
    <button onClick={() => setInvert(!invert)}>Invert</button>
    <button onClick={() => setSepia(!sepia)}>Sepia</button>
    <button onClick={resetEffects}>Reset</button>
  </div>

  {/* DOTS DE PAGINATION */}
  <div className="pagination-dots">
    {Array.from({ length: totalPages }).map((_, i) => (
      <span
        key={i}
        className={`dot ${i === currentPage ? "active" : ""}`}
        onClick={() => setCurrentPage(i)}
      />
    ))}
  </div>

  {/* MINIATURES */}
  <div className="thumbnails">
    {paginatedImages.map((img, i) => (
      <img
        key={i}
        src={img}
        alt={`thumbnail-${i}`}
        className={`thumbnail ${img === selectedImage ? "selected" : ""}`}
        onClick={() => {
          setSelectedImage(img);
          setShowEffect(true);
        }}
      />
    ))}
  </div>
</div>

  );
};

export default Gallery;

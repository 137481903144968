import React from "react";
import '../SupportLogo.css';

const SupportLogo = ({ image, text, link, curve = "bottom" }) => {
  const isTop = curve === "top";
  const arcId = `arc-path-${curve}-${text.replace(/\s/g, "")}`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="support-logo-link">
      <div className="support-logo-container">
        {isTop && (
          <svg viewBox="0 0 200 100" className="arc-text arc-top">
            <defs>
              <path
                id={arcId}
                d="M10,90 A90,90 0 0,1 190,90"
                fill="none"
              />
            </defs>
            <text className="ssss">
              <textPath href={`#${arcId}`} startOffset="50%" textAnchor="middle">
                {text}
              </textPath>
            </text>
          </svg>
        )}

        <img src={image} alt="support logo" className="support-logo-img" />

        {!isTop && (
          <svg viewBox="0 0 200 100" className="arc-text arc-bottom">
            <defs>
              <path
                id={arcId}
                d="M10,10 A90,90 0 0,0 190,10"
                fill="none"
              />
            </defs>
            <text className="texteFooterLogos">
              <textPath href={`#${arcId}`} startOffset="50%" textAnchor="middle">
                {text}
              </textPath>
            </text>
          </svg>
        )}
      </div>
    </a>
  );
};

export default SupportLogo;

import def from "ajv/dist/vocabularies/discriminator";
import React from "react";
import photoOwner from '../images/photoOwner.jpeg'
import GlitchImage from "../components/GlitchImage";

const AboutOwner = () => {

    return (
        <div className="containerAboutTheOwner">
            <h2>About the owner</h2>

            <div className="styleForOwnerPrez">

                <div className="r1-styleForOwnerPrez">
                    {/* <img className="" src={photoOwner} alt="owner's face photo" /> */}
                    <GlitchImage src={photoOwner} />
                </div>


                <div className="r2-styleForOwnerPrez">
                    <p className="owner_bio">
                        Madhav has over 10 years in all aspects of tourism industry in Nepal. 
                        He has started as a porter when he was 16 years old and become fascinated by mountains, nature and villagers. So he decided to become a guide and undertook a training from Nepal Tourism Board in 2012. 
                        Now he is specialized in trekking in every areas in Nepal (Annapurna, Mustang, Manaslu, Solukhumbu/Everest, Langtang, Dhaulagiri, Dolpa..) and also Lumbini area, Chitwan and Bardiya parks and many more. 
                        He knows very well Nepalese, hindu and buddhist culture and philosophy. He speaks Nepalese, English, Hindi and studies French. He loves to share his knowledge with his international customers and he is very proud to make them happy. 
                        He is a very friendly, honest and a passionate guide. His job lets him enough time to take care of his elderly father and to spend time with his family.
                    </p>
                    <p className="owner_sign">
                        <span>Madhav Prasad Khanal</span>
                        <span>Owner, Managing director and guide</span>
                    </p>
                </div>

                    
            </div>
        </div>
    )



}


export default AboutOwner;
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { initGA, trackPage } from "./analytics";
import Home from "./components/Home";
import Region from "./components/Regions";
import TrekDetails from "./components/TrekDetails";
import Gallery from "./components/Gallery";
import Advices from "./components/Advices";
import NotFound from "./components/NotFound";
import AboutOwner from "./components/AboutOwner";

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    trackPage(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/region/:slug" element={<Region />} />
      <Route path="/region/:slug/:trekSlug" element={<TrekDetails />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/advices" element={<Advices />} />
      <Route path="/aboutowner" element={<AboutOwner />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;

import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, Marker, Popup, useMap } from "react-leaflet";
import { DivIcon } from "leaflet";
import { Link } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useRef } from "react";
import nepalRegions from "./np.json";
import summits from "./nepal_summits.json";
import treksData from "./nepal_treks_en.json";
import AboutNepal from "./AboutNepal";
import MountainScroller from "./MountainScroller";
import Compass from "./Compass";
const FixMapDisplay = () => {
  const map = useMap();

  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 200);
  }, [map]);
  return null;
};

const NepalMap = () => {
  const words = [
    "unforgettable",
    "extraordinary",
    "breathtaking",
    "mesmerizing",
    "unbelievable"
  ];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const wordRef = useRef(null); // 👈 ref à passer dans CSSTransition
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const defaultRegion = "Gandaki";
  const [selectedRegion, setSelectedRegion] = useState(defaultRegion);
  const [geoJsonKey, setGeoJsonKey] = useState(0);
  const [selectedTreks, setSelectedTreks] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const trekListRef = useRef(null);

  const treksPerPage = 3;
  const totalPages = Math.ceil(selectedTreks.length / treksPerPage);
  const paginatedTreks = selectedTreks.slice(currentPage * treksPerPage, (currentPage + 1) * treksPerPage);

  useEffect(() => {
    setSelectedTreks(treksData[defaultRegion] || []);
  }, []);

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const getRegionStyle = (feature) => ({
    color: "black",
    weight: 1.5,
    fillColor: selectedRegion === feature.properties.name ? "#5b3c11" : "transparent",
    fillOpacity: selectedRegion === feature.properties.name ? 0.7 : 0.3,
  });

  const onEachRegion = (feature, layer) => {
    layer.on({
      mouseover: () => {
        if (selectedRegion !== feature.properties.name) {
          layer.setStyle({ fillColor: "#5b3c11", fillOpacity: 0.6 });
        }
      },
      mouseout: () => {
        if (selectedRegion !== feature.properties.name) {
          layer.setStyle({ fillColor: "transparent", fillOpacity: 0.3 });
        }
      },
      click: () => {
        setSelectedRegion(feature.properties.name);
        setSelectedTreks(treksData[feature.properties.name] || []);
        setGeoJsonKey((prevKey) => prevKey + 1);
        setCurrentPage(0);
      },
    });
  };

  const createTriangleIcon = () => {
    return new DivIcon({
      html: `<svg width="15" height="15" viewBox="0 0 100 100">
               <polygon points="50,0 100,100 0,100" style="fill:black;"/>
             </svg>`,
      className: "triangle-icon",
      iconSize: [20, 20],
      iconAnchor: [10, 10],
    });
  };

  const renderDifficultyIcons = (difficulty) => {
    return [...Array(5)].map((_, i) => (
      <img
        key={i}
        src="/backpack.png"
        alt="backpack icon"
        style={{
          width: "20px",
          height: "20px",
          opacity: i < difficulty ? 1 : 0.3,
          marginRight: "4px",
        }}
      />
    ));
  };

  const getSummitsByRegion = (region) => {
    return summits.features.filter((s) => s.properties.region === region);
  };

  return (
    <div id="anchornepalmap" className="forWind">
     

      <div className="containerFlagAndTitle">

        <div className="compassAndTitleContainer">
          <p className="p1compassAndTitleContainer">
            {/* <h2 className="titleNepalMapTrek">
            We've prepared some exceptional treks for you
            </h2> */}
<h2 className="titleNepalMapTrek">
  We've prepared some&nbsp;
  <span className="dynamic-word">
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={words[currentWordIndex]}
        timeout={500}
        classNames="word-transition"
        nodeRef={wordRef} // 👈 ajout ici
      >
        <span ref={wordRef}>{words[currentWordIndex]}</span>
      </CSSTransition>
    </SwitchTransition>
  </span>
  &nbsp;treks for you
</h2>




            <span className="instruction">
              click on the different regions of the interactive map and discover our trekking offers in Nepal.
            </span>
          </p>
          
          <p className="p2compassAndTitleContainer">
            <Compass />
          </p>
        </div>

      </div>

      <section className="generalContainerMapAndTreks" id="nepal-map">
        <div className="containerMap">
          <MapContainer
            center={[28.3949, 84.1240]}
            zoom={6.3}
            style={{ height: "600px", width: "100%" }}
            zoomControl={false}
            scrollWheelZoom={false}
            doubleClickZoom={false}
            dragging={false}
            >
            <FixMapDisplay/>
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://carto.com/">CARTO</a>'
            />
            {summits.features.map((summit, index) => (
              <Marker
                key={index}
                position={[summit.geometry.coordinates[1], summit.geometry.coordinates[0]]}
                icon={createTriangleIcon()}
              >
                <Popup>
                  <strong>{summit.properties.name}</strong><br />
                  Altitude: {summit.properties.altitude} m
                </Popup>
              </Marker>
            ))}
            <GeoJSON key={geoJsonKey} data={nepalRegions} style={getRegionStyle} onEachFeature={onEachRegion} />
          </MapContainer>
        </div>

        {selectedRegion && (
          <div className="treks_available">
            <div className="description_treks">
              <h3>{selectedRegion}'s main summits :</h3>
              {/* <ul>
                {getSummitsByRegion(selectedRegion).map((s, idx) => (
                  <li key={idx}>
                    {s.properties.name} - {s.properties.altitude} m
                  </li>
                ))}
              </ul> */}
              {/* 🔥 Affichage des sommets en 1 ou 2 colonnes selon le nombre */}
{(() => {
  const summitsInRegion = getSummitsByRegion(selectedRegion);

  if (summitsInRegion.length <= 5) {
    return (
      <ul>
        {summitsInRegion.map((s, idx) => (
          <li key={idx}>
            {s.properties.name} - {s.properties.altitude} m
          </li>
        ))}
      </ul>
    );
  } else {
    const firstColumn = summitsInRegion.slice(0, 5);
    const secondColumn = summitsInRegion.slice(5);

    return (
      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <ul style={{ flex: 1, borderRight: "1px solid #ccc", paddingRight: "15px" }}>
          {firstColumn.map((s, idx) => (
            <li key={idx}>
              {s.properties.name} - {s.properties.altitude} m
            </li>
          ))}
        </ul>
        <ul style={{ flex: 1, paddingLeft: "15px" }}>
          {secondColumn.map((s, idx) => (
            <li key={idx}>
              {s.properties.name} - {s.properties.altitude} m
            </li>
          ))}
        </ul>
      </div>
    );
  }
})()}


              <h3 style={{ marginTop: "20px" }}>Treks in {selectedRegion}:</h3>

              {/* PAGINATION POINTS EN HAUT */}
              {totalPages > 1 && (
                <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                  {Array.from({ length: totalPages }).map((_, idx) => (
                    <span
                      key={idx}
                      onClick={() => handlePageChange(idx)}
                      style={{
                        display: "inline-block",
                        width: "12px",
                        height: "12px",
                        margin: "0 6px",
                        borderRadius: "50%",
                        backgroundColor: currentPage === idx ? "#5b3c11" : "#ccc",
                        cursor: "pointer"
                      }}
                    />
                  ))}
                </div>
              )}

<div className="switchTransitionContainer">
  <SwitchTransition mode="out-in">
    <CSSTransition
      key={currentPage}
      timeout={300}
      classNames="fade"
      nodeRef={trekListRef}
    >
      <ul ref={trekListRef} style={{ margin: 0, padding: 0 }}
      className="ulTrek">
        {paginatedTreks.map((trek, i) => (
          <li key={i} style={{ marginBottom: "20px" }}>
            <strong>{trek.name}</strong><br />
            <em style={{ color: "#555" }}>{trek.description}</em><br />



            <div className="containerDifficultyAndButton" style={{ display: "flex", alignItems: "center", marginTop: "8px" }} >

              <div className="descriptionAnddifficulty">
                <span style={{ marginRight: "10px", fontWeight: "bold" }} className="descriptionAnddifficulty1">Difficulty:</span>
                <div style={{ display: "flex", alignItems: "center", marginRight: "auto" }} className="descriptionAnddifficulty2">
                {renderDifficultyIcons(trek.difficulty)}
                </div>
              </div>

              <div className="btnSeeMore_DescrTrekMap">
                <Link
                  to={trek["url-trek-detail"] || "/region/annapurna-region/nar-phu-valley-trek"}
                  className="no-underline"
                >
                  <button className="button-86">See more</button>
                </Link>
              </div>
            </div>


          </li>
        ))}
      </ul>
    </CSSTransition>
  </SwitchTransition>
</div>



            </div>
            {/* <button className="button-86" role="button">Discover treks</button> */}
          </div>
        )}
      </section>

      <div className="vk">
        <MountainScroller />
        <AboutNepal />
      </div>
    </div>
  );
};

export default NepalMap;

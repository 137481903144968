import React from "react";
import {
  FaLandmark,
  FaMountain,
  FaLeaf,
  FaUsers,
  FaOm,
  FaCalendarAlt,
  FaPassport
} from "react-icons/fa";
import PrayerFlags from "./PrayerFlags";

const AboutNepal = () => {
  return (<div className="containerFlags">
    <PrayerFlags/>


    <section className="container my-5 containerAboutNepal" id="about_nepal">
        <div className="main_container_title_and_flag_nepal">
            {/* <div className="container_loti_file">
                <Lottie animationData={animationData} loop={true} className='lottieFile' />
            </div> */}
            <div className="container_title_aboutNepal">
                <h2 className="titleNepalSection">About Nepal</h2>
            </div>

            
        </div>
    

      <div className="row">
        <div className="col-md-4">
          <div className="list-group" id="list-tab" role="tablist">
            <a
              className="list-group-item list-group-item-action"
              id="list-history-list"
              data-bs-toggle="list"
              href="#list-history"
              role="tab"
              aria-controls="history"
            >
              <FaLandmark className="me-2" />
              History
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-geo-list"
              data-bs-toggle="list"
              href="#list-geo"
              role="tab"
              aria-controls="geo"
            >
              <FaMountain className="me-2" />
              Geography & Climate
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-nature-list"
              data-bs-toggle="list"
              href="#list-nature"
              role="tab"
              aria-controls="nature"
            >
              <FaLeaf className="me-2" />
              Nature, Wildlife & Plants
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-people-list"
              data-bs-toggle="list"
              href="#list-people"
              role="tab"
              aria-controls="people"
            >
              <FaUsers className="me-2" />
              People & Culture
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-religion-list"
              data-bs-toggle="list"
              href="#list-religion"
              role="tab"
              aria-controls="religion"
            >
              <FaOm className="me-2" />
              Religion
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-festival-list"
              data-bs-toggle="list"
              href="#list-festival"
              role="tab"
              aria-controls="festival"
            >
              <FaCalendarAlt className="me-2" />
              Major Festivals
            </a>
            <a
              className="list-group-item list-group-item-action active"
              id="list-visa-list"
              data-bs-toggle="list"
              href="#list-visa"
              role="tab"
              aria-controls="visa"
            >
              <FaPassport className="me-2" />
              Visa
            </a>
          </div>
        </div>
        <div className="col-md-8">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade"
              id="list-history"
              role="tabpanel"
              aria-labelledby="list-history-list"
            >
              <p>
              Records mention the Gopalas and Mahishapalas believed to have been the earliest rulers with their capital at Matatirtha, the south-west corner of the Kathmandu Valley. From the 7th or 8th Century B.C. the Kirantis are said to have ruled the valley. Their famous King Yalumber is even mentioned in the epic, ‘Mahabharat’. Around 300 A.D. the Lichhavis arrived from northern India and overthrew the Kirantis. One of the legacies of the Lichhavis is the Changu Narayan Temple near Bhaktapur, a UNESCO World Heritage Site (Culture), which dates back to the 5th Century. In the early 7th Century, Amshuvarma, the first Thakuri king took over the throne from his father-in-law who was a Lichhavi. He married off his daughter Bhrikuti to the famous Tibetan King Tsong Tsen Gampo thus establishing good relations with Tibet. The Lichhavis brought art and architecture to the valley but the golden age of creativity arrived in 1200 A.D with the Mallas.
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="list-geo"
              role="tabpanel"
              aria-labelledby="list-geo-list"
            >
              <p>
              Nepal is located in South Asia between China in the north and India in the south, east and west. While the total land area is 147,181 sq. km including water area of the country that is 3,830 sq. km. The geographical coordinates are 28°00′N 84°00′E. Nepal falls in the temperate zone north of the Tropic of Cancer.Nepal’s ecological zones run east to west about 800 km along its Himalayan axis, 150 to 250 km north to south, and is vertically intersected by the river systems. The country can be divided into three main geographical regions: Himalayan region, mid hill region and Terai region. The highest point in the country is Mt. Everest (8,848 m) while the lowest point is in the Terai plains of Kechana Kalan in Jhapa (60 m).
              </p>
              <p>
              Climatic conditions of Nepal vary from one place to another in accordance with the geographical features. In the north summers are cool and winters severe, while in south summers are tropical and winters are mild. Nepal has namely five major seasons: spring, summer, monsoon, autumn and winter.An average temperature drop of 6°C occurs for every 1,000 m gain in altitude. In the Terai, summer temperatures exceed 37° C and higher in some areas, winter temperatures range from 7°C to 23°C in the Terai. In mountainous regions, hills and valleys, summers are temperate while winter temperatures can plummet under sub zero. The valley of Kathmandu has a pleasant climate with average summer and winter temperatures of 19°C – 35°C and 2°C – 12°C respectively.
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="list-nature"
              role="tabpanel"
              aria-labelledby="list-nature-list"
            >
              <p>
              Nepal’s 20 protected areas cover 23.23 percent of its land. Its 10 national parks, three wildlife reserves, six conservations areas and one hunting reserve cover various geographical locations from the sub-tropical Terai jungles to the arctic Himalayan region. Two of Nepal’s natural areas are listed by UNESCO as Natural World Heritage Sites. They are: Chitwan National Park and Sagarmatha National Park.Comprising only 0.1 percent of the total land area on a global scale, Nepal possesses a disproportionately rich biodiversity. Of the total number of species found globally, Nepal possesses 2.80 percent plants, 3.96 percent mammals, 3.72 percent butterflies and 8.9 percent of birds. Of 6,391 species of flowering plants recorded in Nepal, 399 are endemic. Among the 399 endemic flowering plants in Nepal, 63 percent are from the high mountains, 38 percent from the mid hills, and 5 percent from the Terai and Siwaliks. Similarly, the central region contains 66 percent of the total endemic species followed by western (32 percent) and eastern regions (29 percent).
              </p>
              <p>
                Of the total number of species found globally, 3.96 percent mammals, 3.72 percent butterflies and 8.9 percent of birds. Wildlife of Nepal is officially classified into two main categories: common and protected. The common category lists such species as common leopard, spotted deer, Himalayan tahr, blue sheep and others. These species are commonly seen in the wild. The protected species include 26 mammals, nine birds and three reptiles. These rare animals are confined to their prime habitats.
              </p>
              <p>
              Of the total number of species found globally, Nepal possesses 2.80 percent plants. Record from 2006 shows that Nepal has 6,391 flowering plant species, representing 1,590 genera and 231 families. Nepal’s share of flowering plant species is 2.76 percent of the global total compared to earlier records of 2.36 percent. Nepal’s share of pteriodophytes is 5.15 percent compared to earlier records of 4.45 percent.
              There are 2,532 species of vascular plants represented by 1,034 genera and 199 families in the protected sites. Some 130 endemic species are found in the protected sites.
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="list-people"
              role="tabpanel"
              aria-labelledby="list-people-list"
            >
              <p>The population of Nepal was recorded to be about 26.62 million according to a recent survey done by the Central Bureau of Statistics, Nepal.  The population comprises of about a 101 ethnic groups speaking over 92 languages. The distinction in caste and ethnicity is understood more easily with a view of customary layout of the population.Though, there exist numerous dialects, the language of unification is the national language, Nepali. Nepali is the official language of the state, spoken and understood by majority of the population. Multiple ethnic groups have their own mother tongues. English is spoken by many in Government and business offices. It is the mode of education in most private schools of Kathmandu and some other cities.</p>
              <p>
              Customs and traditions differ from one part of Nepal to another. A conglomeration lies in capital city Kathmandu where cultures are blending to form a national identity. Kathmandu Valley has served as the country’s cultural metropolis since the unification of Nepal in the 18th Century.A prominent factor in a Nepali’s everyday life is religion. Adding color to the lives of Nepalis are festivals the year round which they celebrate with much pomp and joy. Food plays an important role in the celebration of these festivals.
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="list-religion"
              role="tabpanel"
              aria-labelledby="list-religion-list"
            >
              <p>
              Customs and traditions differ from one part of Nepal to another. A conglomeration lies in capital city Kathmandu where cultures are blending to form a national identity. Kathmandu Valley has served as the country’s cultural metropolis since the unification of Nepal in the 18th Century.A prominent factor in a Nepali’s everyday life is religion. Adding color to the lives of Nepalis are festivals the year round which they celebrate with much pomp and joy. Food plays an important role in the celebration of these festivals.
              </p>
            </div>
            <div
              className="tab-pane fade show active"
              id="list-visa"
              role="tabpanel"
              aria-labelledby="list-festival-visa"
            >
              <p>
                <ul>
<div>

  <p>
    Foreigner who intends to visit Nepal must hold valid passport or any travel document equivalent to passport issued by the Govt. for visiting a foreign country prior to apply for visa
  </p>
  <p>
  a) Entry:
  Tribhuvan International Airport is the only international airport of Nepal. Immigration Office, TIA (Tribhuvan International Airport) under Department of Immigration has been facilitating tourists flying to Nepal by providing Visa on Arrival. ‘On Arrival’ visa procedure is very quick and simple. You can expect some queues during peak Tourist season. If you wish to skip those queues, you can also consider getting Visa from Nepalese Diplomatic Missions stationed abroad prior to your arrival. Choice is yours.
  </p>
  <p>
  b)For Chinese
  Chinese citizen are requested to apply in Nepalese Embassy or other Nepalese diplomatic missions as there is no provision of on arrival visa for them.
  </p>
  <p>
  c) Visa Fee:
Visa Fee required to obtain Tourist Visa from Nepalese diplomatic agencies and entry points:
1. US $ 25 or equivalent foreign currency for Tourist Visa with Multiple Entry for 15 days.
2. US $ 40 or equivalent foreign currency for Tourist Visa with Multiple Entry for 30 days.
3. US $ 100 or equivalent foreign currency for Tourist Visa with Multiple Entry for 100 days.
4. Regardless of the provision stated in 1(a) and 1(b), tourists with passport from South Asian Association for Regional Cooperation (SAARC) nations aren’t required to pay visa fee for 30 days.
  </p>
  <p>
  d) Fee required to extend duration of visa or renew visa:
– US $ 2 or equivalent Nepalese currency per day for extension.
– Additional US $ 20 or equivalent Nepalese currency on visa fee as per 2(b), if Multiple Entry facility is required for the extended period.
– If foreign visitors that haven’t renewed their visa want to renew their visa, they have to pay additional Nepalese currency equivalent to US 3$ on the regular visa extension fee.
– Foreign visitors who have overstayed the visa period of 150 days without extension are required to pay visa fee as per provision in 2(c) and punishment as per Clause 10 sub-clause 4 of Immigration Act 2049.
– Regardless of provision stated in 2(a), 15 days is counted as minimum extension period and visa fee is charged accordingly. For extension period more than 15 days, visa fee is charged as per the provision of 2(a).
  </p>




</div>
                </ul>
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="list-festival"
              role="tabpanel"
              aria-labelledby="list-festival-list"
            >
              <p>
                <ul>
                    <li>Dashain</li>
                    <li>Brother -Sister (Tihar) festival</li>
                    <li>Teej (Special for women)</li>
                    <li>Nepali and English new year</li>
                    <li>Indra Jatra</li>
                    <li>Ghode Jatra</li>
                    <li>Janai Purnima</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

    </section>

        </div>
  );
};

export default AboutNepal;
